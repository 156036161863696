import {
  getBrowserCookie,
  getBrowserCookies,
  setBrowserCookie,
} from '@moonpig/web-core-cookies'
import { PageTypeEnum } from '../../types'

const COOKIE_MAX_AGE = 60 * 60 * 24 * 14 // 14 days
const COOKIE_VALUE = 'true'

export const areFunctionalCookiesEnabled = () => {
  const cookies = getBrowserCookies()
  const oneTrustCookie = cookies.OptanonConsent
  const oneTrustTargetingGroup = 'C0003:1'

  return oneTrustCookie
    ? oneTrustCookie.includes(oneTrustTargetingGroup)
    : false
}

export const setCSATTileCookie = (pageType: PageTypeEnum) => {
  const cookieMapping: Partial<Record<PageTypeEnum, string>> = {
    gallery: 'gallery_results_csat_tile',
    search: 'search_results_csat_tile',
    dynamic: 'search_results_csat_tile',
  }

  const cookieName = cookieMapping[pageType]

  if (cookieName) {
    const cookieExists = getBrowserCookie(cookieName)

    if (!cookieExists) {
      setBrowserCookie(cookieName, COOKIE_VALUE, {
        maxAge: COOKIE_MAX_AGE,
        path: '/',
      })
    }
  }
}
