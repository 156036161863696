import { Experiments } from '@moonpig/web-core-experiments'
import { Region } from '@moonpig/web-core-types'
import { type AllFlagIDs } from '@moonpig/web-explore-flags'
import { getCardVariantIdsEnabled } from '@moonpig/web-shared-products'
import { SearchFeatures } from '../types/searchFeatures'

export type LoadFeaturesProps = {
  region: Region
  experiments: Experiments
  flags: Partial<Record<AllFlagIDs, boolean>>
}

export const loadFeatures: (props: LoadFeaturesProps) => SearchFeatures = ({
  experiments,
  flags,
}) => {
  const isFlagOn = (key: string) => flags[key as keyof typeof flags] ?? false
  const isExperimentEnabled = (key: string) =>
    experiments[key]?.toLowerCase() === 'enabled'

  const showCollageMaker = isFlagOn('search-show-collage-maker')
  const showGiftVouchers = isExperimentEnabled('show-gift-vouchers')

  const cardVariants = getCardVariantIdsEnabled()

  const experimentValues = ['search-use-new-ia-index=true']
    .filter(Boolean)
    .join('&')

  return {
    showGiftVouchers,
    searchUseNewIaIndex: true,
    showCollageMaker,
    cardVariants,
    experimentValues,
    nbaAlgorithm: 'NBA_5',
  }
}
