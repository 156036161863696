import React, { FC, useEffect, useState } from 'react'
import { Box, IconButton, Text } from '@moonpig/launchpad-components'
import {
  IconSystemCrossSmall,
  IconSystemThumbsUp,
  IconSystemThumbsDown,
} from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue, spacingPx } from '@moonpig/launchpad-theme'
import { getBrowserCookie } from '@moonpig/web-core-cookies'
import {
  trackGAEvent,
  createGenericSelectContentEvent,
} from '@moonpig/web-core-analytics'
import { useProductsLocaleText } from '../../locale'
import { FEEDBACK_BUTTON_SIZE_PX } from './constants'
import { PageTypeEnum } from '../../types'
import { areFunctionalCookiesEnabled, setCSATTileCookie } from './csatCookies'

const StyledCustomerSatisfactionTile = styled(Box)`
  ${s({
    mb: { xs: 6, md: 4 },
    width: '100%',
  })}
`

const StyledWrapper = styled(Box)`
  ${s({
    py: { xs: 8, md: 6 },
    mx: { xs: 3, md: 4, lg: 5, xxl: 9 },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: { xs: 'start', md: 'center' },
    bgcolor: 'colorBackground02',
    borderRadius: 2,
    border: '1px',
    borderColor: 'colorBorder03',
  })}
`

const StyledContent = styled.div`
  ${s({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    position: 'relative',
    ml: 12,
  })}
  gap: ${spacingPx(6)};
`
const StyledButtonsContainer = styled(Box)`
  ${s({
    flex: 1 / 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  })}
  gap: ${spacingPx(4)};
`

const StyledIconButtonClose = styled(IconButton)`
  flex: 1/8;
  ${s({
    color: 'colorInteractionIcon',
    mt: { xs: -8, md: 0 },
    mr: { md: 6 },
  })}
`

const FeedbackButton = styled(IconButton)`
  ${s({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: FEEDBACK_BUTTON_SIZE_PX,
    width: FEEDBACK_BUTTON_SIZE_PX,
    padding: 5,
    border: '1px solid',
    borderColor: 'colorBorder03',
    bgcolor: 'colorBlack00',
  })}
  cursor: pointer;
  border-radius: 50%;

  &:focus {
    ${s({
      bgcolor: 'colorBorder04',
    })}
  }

  &:hover,
  &:active {
    transition: all 150ms ease-in-out;
  }

  &:active {
    svg {
      transform: scale(0.9);
    }
  }

  & + input[type='radio']:active {
    outline: 2px solid ${colorValue('colorBorder04')};
    outline-offset: 2px;
  }
`

const StyledThumbsUpButton = styled(FeedbackButton)`
  ${s({
    color: 'colorFeedbackSuccess',
  })}

  &:hover,
  &:active {
    ${s({
      bgcolor: 'colorBackgroundSuccess',
      borderColor: 'colorFeedbackSuccess',
    })}
    svg {
      ${s({
        color: 'colorFeedbackSuccess',
      })}
    }
  }

  &:focus {
    &:active {
      ${s({
        bgcolor: 'colorBackgroundSuccess',
      })}
    }
    svg {
      ${s({
        color: 'colorFeedbackSuccess',
      })}
    }
    outline: 2px solid ${colorValue('colorFeedbackSuccess')};
  }
`

const StyledThumbsDownButton = styled(FeedbackButton)`
  ${s({
    color: 'colorFeedbackError',
  })}
  border-radius: 50%;

  &:hover,
  &:active {
    ${s({
      bgcolor: 'colorBackgroundError',
      borderColor: 'colorFeedbackError',
    })}
    svg {
      ${s({
        color: 'colorFeedbackError',
      })}
    }
  }

  &:focus {
    &:active {
      ${s({
        bgcolor: 'colorBackgroundError',
      })}
    }
    svg {
      ${s({
        color: 'colorFeedbackError',
      })}
    }
  }
`

const StiledTitle = styled(Text)`
  ${s({
    typography: {
      xs: 'typeDisplay06',
    },
    textAlign: 'center',
  })}
`
enum FeedbackResponse {
  thumbsUp = 'thumbs up',
  thumbsDown = 'thumbs down',
}

type Props = {
  pageType: PageTypeEnum
}

export const CustomerSatisfactionTile: FC<Props> = ({ pageType }) => {
  const t = useProductsLocaleText()
  const [customerSelection, setCustomerSelection] =
    useState<FeedbackResponse | null>(null)
  const [show, setShow] = useState(false)

  const handleOnClick = (selection: FeedbackResponse) => {
    setCSATTileCookie(pageType)
    trackGAEvent(
      createGenericSelectContentEvent({
        eventCategory: pageType,
        eventAction: 'answer customer satisfaction tile',
        eventLabel: `${t(
          'customer_satisfaction_tile.question',
        )} | ${selection}`,
      }),
    )

    setCustomerSelection(selection)
  }

  const handleClose = () => {
    setCSATTileCookie(pageType)
    trackGAEvent(
      createGenericSelectContentEvent({
        eventCategory: pageType,
        eventAction: 'close customer satisfaction tile',
        eventLabel: `${t('customer_satisfaction_tile.question')} | close`,
      }),
    )
    setShow(false)
  }

  useEffect(() => {
    const isGalleryCookieUnset =
      pageType === PageTypeEnum.GALLERY &&
      !getBrowserCookie('gallery_results_csat_tile')
    const isSearchCookieUnset =
      pageType === PageTypeEnum.SEARCH &&
      !getBrowserCookie('search_results_csat_tile')

    setShow(
      areFunctionalCookiesEnabled() &&
        (isGalleryCookieUnset || isSearchCookieUnset),
    )
  }, [pageType])

  return show ? (
    <StyledCustomerSatisfactionTile data-testid="customer-satisfaction-tile">
      <StyledWrapper>
        <StyledContent>
          {customerSelection ? (
            <StiledTitle>
              {t('customer_satisfaction_tile.thankyou')}
            </StiledTitle>
          ) : (
            <>
              <StiledTitle>
                {t('customer_satisfaction_tile.question')}
              </StiledTitle>
              <StyledButtonsContainer>
                <StyledThumbsUpButton
                  icon={IconSystemThumbsUp}
                  width={24}
                  height={24}
                  label={t('customer_satisfaction_tile.yes')}
                  onClick={() => handleOnClick(FeedbackResponse.thumbsUp)}
                />
                <StyledThumbsDownButton
                  icon={IconSystemThumbsDown}
                  width={24}
                  height={24}
                  label={t('customer_satisfaction_tile.no')}
                  onClick={() => handleOnClick(FeedbackResponse.thumbsDown)}
                />
              </StyledButtonsContainer>
            </>
          )}
        </StyledContent>
        <StyledIconButtonClose
          width="24px"
          height="24px"
          label={t('customer_satisfaction_tile.close')}
          icon={IconSystemCrossSmall}
          onClick={handleClose}
        />
      </StyledWrapper>
    </StyledCustomerSatisfactionTile>
  ) : null
}
